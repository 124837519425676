@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.invisible {
  display: none;
}

.visible {
  text-align: center;
  visibility: visible;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.thread-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.thread {
  border-top: 0.1px solid gray;
}

.download-container {
  text-align: center;
  padding: 5rem;
}
.download-title {
  font-size: 36px;
  font-weight: bold;
}
.download-title-2 {
  font-size: 24px;
}

.download-icons a {
  color: gray;
  padding: 1rem;
  transition: 0.5s;
}

.download-icons a:hover {
  color: black;
  padding: 1.5rem;
}

.download-vize {
  font-size: 48px;
}

/* Fading effect */
.fade-in-1 {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-2 {
  animation: fadeIn ease 8s;
  -webkit-animation: fadeIn ease 8s;
  -moz-animation: fadeIn ease 8s;
  -o-animation: fadeIn ease 8s;
  -ms-animation: fadeIn ease 8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-3 {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.StyledButton_styledButton__Lsu3A {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  margin: 5px 0;
  font-size: 15px;
  border-radius: 8px;
  border: 0;
  background: #4561e1;
  font-family: DM Sans;
  color: white;
  cursor: pointer;
  resize: none;
}

.StyledLoadingIndicator_styledLoadingIndicator__3jSS0 {
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  -webkit-animation: StyledLoadingIndicator_spin__2PD9t 2s linear infinite;
          animation: StyledLoadingIndicator_spin__2PD9t 2s linear infinite;
}

@-webkit-keyframes StyledLoadingIndicator_spin__2PD9t {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes StyledLoadingIndicator_spin__2PD9t {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.checkout-title {
  display: flex;
}

.checkout-title button {
  border: none;
  background-color: #ffffff;
  color: #4561e1;
  font-size: large;
}

.checkout-title button:hover {
  color: #c5c7ce;
}

.total {
  display: flex;
  justify-content: space-evenly;
  background: #f5f5f5;
  padding: 2rem;
  text-decoration: none;
}

.items-number {
  padding: 0rem 2rem;
}

.shopping {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.trash {
  border: none;
  background-color: #f5f5f5;
  transition: 0.5s;
}

.trash:hover {
  background-color: #dbdbdb;
}

.comment {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
}

.comment textarea {
  height: 275px;
  background-color: #f5f5f5;
  border: none;
  margin: 1rem;
  padding: 0;
}

.checkout-form {
  background-color: #f5f5f5;
  padding: 2rem;
}

.checkout-list li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  padding: 1rem;
  margin: 0;
}

.checkout-button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background: #3f4957;
  color: white;
  width: 90%;
  align-self: center;
  transition: 0.5s;
}

.checkout-button:hover {
  background-color: #637386;
}

.checkout-button-container {
  display: flex;
  justify-content: center;
}

.checkout-input {
  width: 100%;
  margin: auto;
}

.checkout-input input {
  border: none;
  border-radius: 10px;
  height: 50px;
  margin: 0.5rem;
  width: 80%;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: black; /* Fallback color */
}
.modal-button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background: #3f4957;
  color: white;
  width: 100%;
  align-self: center;
  transition: 0.5s;
}

.modal-button:hover {
  background-color: #637386;
}

.StyledFormWithInput_styledFormWithInput__q_eZc {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

html {
  font-family: 'DM Sans', sans-serif !important;
}

body {
  font-style: normal;
  font-size: 15px;
}

i {
  font-style: normal !important;
}

.content {
  background-color: #f5f5f5;
  min-height: 100vh;
  height: auto;
  padding: 2rem;
}

.greeting {
  justify-content: center;
  background-color: #ffffff;
  padding: 4rem;
  margin: 0 12rem;
}

h1 {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 30px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  font-family: 'DM Sans';
}

.schedule {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding: 4rem;
  margin: 2rem 12rem;
}

.content select {
  background: #ffffff;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.avail-days {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c20d0d;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  box-sizing: border-box;
  color: #3f4957;
  padding: 1rem;
  margin: 1rem 0;
}

.day ::checked {
  color: #5551ff;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

